<template>
  <div class="home-page">
    <!-- 轮播 -->
    <div class="banner">
      <a-carousel :after-change="onChange" autoplay :dots="false">
        <div class="banner_item">
          <!-- <img src="@/assets/images/home/banner.png" alt="banner" /> -->
          <img src="@/assets/images/home/banner.png" alt="banner" />
          <div class="moban">
            <div class="moban_title">
              <h2>1㎡ 改造计划，让您餐厅的厨房</h2>
              <h2><span>20%</span>能效提高</h2>
            </div>
            <div class="mobna_more">了解更多</div>
          </div>
        </div>
      </a-carousel>
    </div>

    <!-- 内容 -->
    <div class="container">
      <section class="section_one">
        <div class="section_tille db-mb48">
          <h2>在厨窗，找到更多餐厅设计方案</h2>
          <div class="see_more">
            <span>查看更多</span>
            <img
              src="@/assets/images/icons/arrow-right.png"
              alt="arrow-right"
            />
          </div>
        </div>
        <div class="scrollcardbox">
          <swiper
            :slides-per-view="1.313"
            :space-between="24"
            :free-mode="true"
            :observer="true"
            :pagination="{
              clickable: true,
              bulletClass: 'my-bullet', //需设置.my-bullet样式
              bulletActiveClass: 'my-bullet-active',
            }"
            :navigation="{
              nextEl: '.scroll-btn-right1',
              prevEl: '.scroll-btn-Left1',
            }"
            class="mySwiper"
            @slideChange="onSlideChange1"
          >
            <swiper-slide>
              <div class="scrollcard-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
                <div class="scrollcard-item-info">
                  <span>如何打造一个高效节能的西式快餐店？</span>
                  <div class="more">查看更多</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="scrollcard-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
                <div class="scrollcard-item-info">
                  <span>20 ㎡ 包子店厨房 方案</span>
                  <div class="more">查看更多</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="scrollcard-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
                <div class="scrollcard-item-info">
                  <span>如何打造一个高效节能的西式快餐店？</span>
                  <div class="more">查看更多</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="scrollcard-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
                <div class="scrollcard-item-info">
                  <span>如何打造一个高效节能的西式快餐店？</span>
                  <div class="more">查看更多</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <div
            v-show="swiperIndex1 != 0"
            class="scroll-btn-Left scroll-btn-Left1"
          >
            <img
              src="@/assets/images/icons/arrow-right-shadow.png"
              alt="arrow-left"
            />
          </div>
          <div
            v-show="swiperIndex1 != 3"
            class="scroll-btn-right scroll-btn-right1"
          >
            <img
              src="@/assets/images/icons/arrow-right-shadow.png"
              alt="arrow-right"
            />
          </div>
        </div>
      </section>
      <section class="section-two">
        <div class="section_tille">
          <h2>智慧餐饮，从设备互联开始</h2>
          <div class="see_more">
            <span>查看更多</span>
            <img
              src="@/assets/images/icons/arrow-right.png"
              alt="arrow-right"
            />
          </div>
        </div>
        <div class="block" />
        <div class="sectionTwoScroll">
          <swiper
            :slides-per-view="3"
            :space-between="24"
            :free-mode="true"
            :observer="true"
            :pagination="{
              clickable: true,
              bulletClass: 'my-bullet', //需设置.my-bullet样式
              bulletActiveClass: 'my-bullet-active',
            }"
            :navigation="{
              nextEl: '.scroll-btn-right2',
              prevEl: '.scroll-btn-Left2',
            }"
            class="mySwiper"
            @slideChange="onSlideChange2"
          >
            <swiper-slide>
              <div class="sectionTwoScroll-item">
                <img src="@/assets/images/home/section-two1.png" alt="items" />
                <div class="sectionTwoScroll-item-info">
                  <div class="sectionTwoScroll-item-title">
                    <span>原材料就是餐厅的心脏</span>
                    <span>智慧冷储，掌中控制你的心脏</span>
                  </div>
                  <div class="sectionTwoScroll-item-price">
                    <div class="sts-i-p-title">智能冷链系列</div>
                    <div class="sts-i-p-more">
                      <span>￥3000.00起</span>
                      <img
                        src="@/assets/images/icons/arrow-right-second.png"
                        alt="arrow-right"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionTwoScroll-item">
                <img src="@/assets/images/home/section-two2.png" alt="items" />
                <div class="sectionTwoScroll-item-info">
                  <div class="sectionTwoScroll-item-title">
                    <span>原材料就是餐厅的心脏</span>
                    <span>智慧冷储，掌中控制你的心脏</span>
                  </div>
                  <div class="sectionTwoScroll-item-price">
                    <div class="sts-i-p-title">智能冷链系列</div>
                    <div class="sts-i-p-more">
                      <span>￥3000.00起</span>
                      <img
                        src="@/assets/images/icons/arrow-right-second.png"
                        alt="arrow-right"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionTwoScroll-item">
                <img src="@/assets/images/home/section-two3.png" alt="items" />
                <div class="sectionTwoScroll-item-info">
                  <div class="sectionTwoScroll-item-title">
                    <span>原材料就是餐厅的心脏</span>
                    <span>智慧冷储，掌中控制你的心脏</span>
                  </div>
                  <div class="sectionTwoScroll-item-price">
                    <div class="sts-i-p-title">智能冷链系列</div>
                    <div class="sts-i-p-more">
                      <span>￥3000.00起</span>
                      <img
                        src="@/assets/images/icons/arrow-right-second.png"
                        alt="arrow-right"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionTwoScroll-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
                <div class="sectionTwoScroll-item-info">
                  <div class="sectionTwoScroll-item-title">
                    <span>原材料就是餐厅的心脏</span>
                    <span>智慧冷储，掌中控制你的心脏</span>
                  </div>
                  <div class="sectionTwoScroll-item-price">
                    <div class="sts-i-p-title">智能冷链系列</div>
                    <div class="sts-i-p-more">
                      <span>￥3000.00起</span>
                      <img
                        src="@/assets/images/icons/arrow-right-second.png"
                        alt="arrow-right"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <div
            v-show="swiperIndex2 != 0"
            class="scroll-btn-Left scroll-btn-Left2"
          >
            <img
              src="@/assets/images/icons/arrow-right-shadow.png"
              alt="arrow-left"
            />
          </div>
          <div
            v-show="swiperIndex2 != 1"
            class="scroll-btn-right scroll-btn-right2"
          >
            <img
              src="@/assets/images/icons/arrow-right-shadow.png"
              alt="arrow-right"
            />
          </div>
        </div>
      </section>

      <section class="section-three">
        <div class="section_tille db-mb48">
          <h2>成为厨粉，尊享会员礼遇</h2>
        </div>
        <div class="sectionThreeScroll">
          <swiper
            :slides-per-view="1"
            :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }"
            class="mySwiper"
          >
            <swiper-slide>
              <div class="sectionThreeScroll-item">
                <img src="@/assets/images/home/section-two1.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionThreeScroll-item">
                <img src="@/assets/images/home/section-two2.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionThreeScroll-item">
                <img src="@/assets/images/home/section-two3.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionThreeScroll-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>

      <section class="section-four">
        <div class="section_tille db-mb48">
          <h2>热销设备分类</h2>
          <div class="see_more">
            <span>查看更多</span>
            <img
              src="@/assets/images/icons/arrow-right.png"
              alt="arrow-right"
            />
          </div>
        </div>
        <div class="sectionFourScroll">
          <swiper
            :slides-per-view="4"
            :space-between="24"
            :free-mode="true"
            :pagination="{
              clickable: true,
              bulletClass: 'my-bullet', //需设置.my-bullet样式
              bulletActiveClass: 'my-bullet-active',
            }"
            :navigation="{
              nextEl: '.scroll-btn-right4',
              prevEl: '.scroll-btn-Left4',
            }"
            class="mySwiper"
            @slideChange="onSlideChange4"
          >
            <swiper-slide>
              <div class="sectionFourScroll-item">
                <img src="@/assets/images/home/section-two1.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionFourScroll-item">
                <img src="@/assets/images/home/section-two2.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionFourScroll-item">
                <img src="@/assets/images/home/section-two3.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionFourScroll-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionFourScroll-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="sectionFourScroll-item">
                <img src="@/assets/images/home/section1.png" alt="items" />
              </div>
            </swiper-slide>
          </swiper>
          <div
            v-show="swiperIndex4 != 0"
            class="scroll-btn-Left scroll-btn-Left4"
          >
            <img
              src="@/assets/images/icons/arrow-right-shadow.png"
              alt="arrow-left"
            />
          </div>
          <div
            v-show="swiperIndex4 != 2"
            class="scroll-btn-right scroll-btn-right4"
          >
            <img
              src="@/assets/images/icons/arrow-right-shadow.png"
              alt="arrow-right"
            />
          </div>
        </div>
      </section>

      <section class="section-five">
        <div class="section_tille db-mb48">
          <h2>多样化服务 . 超值活动</h2>
        </div>
        <div class="sectionFive">
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five1.png" alt="img" />
            <div class="sectionFive-item-info">
              <div class="sf-i-title">
                <div>咖啡店必备组合</div>
                <div>组合价 ￥3000起</div>
              </div>
              <div class="sf-i-more">
                <span>查看更多</span>
                <img
                  src="@/assets/images/icons/arrow-right-second.png"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five2.png" alt="img" />
            <div class="sectionFive-item-info">
              <div class="sf-i-title">
                <div>全品类餐饮厨房设计</div>
                <div>1对1专业设计服务</div>
              </div>
              <div class="sf-i-more">
                <span>为您提供完整的安装服务</span>
                <img
                  src="@/assets/images/icons/arrow-right-second.png"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five3.png" alt="img" />
            <div class="sectionFive-item-info">
              <div class="sf-i-title">
                <div>全品类厨房设备商城</div>
                <div>设备 耗材</div>
              </div>
              <div class="sf-i-more">
                <span>找到最适合您餐厅的设备</span>
                <img
                  src="@/assets/images/icons/arrow-right-second.png"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five4.png" alt="img" />
            <div class="sectionFive-item-info">
              <div class="sf-i-title">
                <div>厨窗安装服务</div>
                <div>送货 安装 培训 售后</div>
              </div>
              <div class="sf-i-more">
                <span>为您提供完整的安装服务</span>
                <img
                  src="@/assets/images/icons/arrow-right-second.png"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five6.png" alt="img" />
            <div class="sectionFive-item-info">
              <div class="sf-i-more">
                <span>更多活动...</span>
                <img
                  src="@/assets/images/icons/arrow-right-second.png"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five6.png" alt="img" />
            <div class="sectionFive-item-info">
              <div class="sf-i-title">
                <div>对公业务</div>
                <div>整体厨房设计 批量设备采购</div>
              </div>
              <div class="sf-i-more">
                <span>全程服务，助你顺利开业</span>
                <img
                  src="@/assets/images/icons/arrow-right-second.png"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="sectionFive-item">
            <img src="@/assets/images/home/section-five7.png" alt="img" />
          </div>
        </div>
      </section>

      <section class="section-six">
        <div class="section_tille db-mb48">
          <h2>用更多设计灵感，发现您餐厅的无限价值</h2>
        </div>
        <div class="sectionSix">
          <div class="sectionSix-item">
            <img src="@/assets/images/home/section-five7.png" alt="item" />
          </div>
          <div class="sectionSix-item">
            <img src="@/assets/images/home/section-five7.png" alt="item" />
          </div>
          <div class="sectionSix-item">
            <img src="@/assets/images/home/section-five7.png" alt="item" />
          </div>
        </div>
      </section>

      <div
        class="find-more"
        :class="isFindMore ? 'isFindMoreStyle' : ''"
        @click="clickFindMore"
      >
        探索更多设计灵感
      </div>
    </div>
    <!-- 内容 -->
    <toTop />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
require("swiper/swiper.less");
require("swiper/components/pagination/pagination.min.css");
import toTop from "@/components/toTop";
// import { fanyiFun } from '@/api/goods'
// import request from '@/utils/request'
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    toTop,
  },
  data() {
    return {
      swiperIndex1: 0,
      swiperIndex2: 0,
      swiperIndex4: 0,
      isFindMore: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    clickFindMore() {
      // 查看更多
      this.isFindMore = true;
      setTimeout(() => {
        this.isFindMore = false;
      }, 300);
    },
    onSlideChange1(e) {
      // 设计方案轮播
      this.swiperIndex1 = e.activeIndex;
    },
    onSlideChange2(e) {
      // 智慧餐饮轮播
      this.swiperIndex2 = e.activeIndex;
    },
    onSlideChange4(e) {
      // 热销设备分类
      this.swiperIndex4 = e.activeIndex;
    },
    onChange(current) {
      // console.log(current)
    },
    getList(text = "中国") {
      // const data = {
      //   doctype: 'json',
      //   type: 'auto',
      //   i: text
      // }
      // fanyiFun(data).then(res => {
      //   console.log(res)
      // })
      // jsonp(`http://fanyi.youdao.com/translate?doctype=json&type=AUTO&i=${text}`).then(res => {
      //   console.log(res)
      // })
      // request({
      //   url: `http://fanyi.youdao.com/translate?doctype=json&type=AUTO&i=${text}`
      // })
    },
  },
};
</script>
<style lang='less' scoped>
.home-page {
  // banner 图
  .banner {
    width: 100%;
    height: 800px;
    margin-bottom: 80px;

    .banner_item {
      width: 100%;
      height: 800px;
      position: relative;

      & > img {
        width: 100%;
        height: 800px;
      }

      .moban {
        width: 728px;
        height: 320px;
        position: absolute;
        right: 144px;
        bottom: 200px;
        color: #fff;

        .moban_title {
          width: 100%;
          height: 200px;

          & > h2 {
            color: #fff;
            font-size: 48px;
            margin: 0;
          }
          & > h2 > span {
            color: #e13714;
            margin-right: 32px;
          }
        }

        .mobna_more {
          width: 240px;
          height: 72px;
          background-color: @bg-color;
          text-align: center;
          line-height: 66px;
          font-size: 36px;
          font-weight: 400;
        }
      }
    }
  }
  .section-three {
    margin-bottom: 0;
  }
  section {
    margin-bottom: 80px;

    .block {
      width: 240px;
      height: 48px;
      // background-color: #9FD1BB;
    }

    // 内容区的标题
    .section_tille {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > h2 {
        color: #333333;
        font-weight: bold;
        font-size: 40px;
      }
      .see_more {
        color: #999999;
        font-size: 16px;
        cursor: pointer;

        & img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
          vertical-align: bottom;
        }
        &:hover {
          color: @color;
        }
      }
    }

    // section 轮播1
    .scrollcardbox {
      position: relative;

      .scrollcard-item {
        position: relative;

        & > img {
          width: 1000px;
          height: 600px;
        }

        .scrollcard-item-info {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 372px;
          height: 223px;
          background-color: @bg-color;
          color: #ffffff;
          font-size: 36px;
          font-weight: bold;
          padding: 24px;
          line-height: 48px;

          .more {
            width: 104px;
            height: 48px;
            background-color: #ffffff;
            color: @color;
            font-size: 16px;
            text-align: center;
            line-height: 48px;
            margin-top: 32px;
          }
        }
      }
    }

    // section 轮播2
    .sectionTwoScroll {
      position: relative;

      .sectionTwoScroll-item {
        position: relative;
        z-index: 1;
        transition: all 0.2s linear;
        cursor: pointer;

        & > img {
          width: 424px;
          height: 566px;
        }

        &:hover {
          z-index: 2;
          box-shadow: 0 15px 30px rgb(0 0 0 / 25%);
          transform: translate3d(0, 2px, 0);

          .sectionTwoScroll-item-info {
            opacity: 1;
          }
        }

        .sectionTwoScroll-item-info {
          opacity: 0;
          width: 424px;
          height: 566px;
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          transition: all 0.3s linear;

          .sectionTwoScroll-item-title {
            padding: 24px;
            font-weight: 400;
            font-size: 14px;
            span {
              display: block;
            }

            & > span:first-child {
              font-size: 24px;
              margin-bottom: 16px;
            }
          }

          .sectionTwoScroll-item-price {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 130px;
            background-color: @bg-color;
            padding: 24px;
            font-weight: bold;

            .sts-i-p-title {
              width: 100%;
              height: 21px;
              font-size: 16px;
              margin-bottom: 24px;
              line-height: 21px;
            }
            .sts-i-p-more {
              display: flex;
              justify-content: space-between;
              align-items: center;

              & > span {
                font-size: 28px;
                font-weight: bold;
              }

              & > img {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }

    // section3 轮播3
    .sectionThreeScroll {
      position: relative;

      .sectionThreeScroll-item {
        & > img {
          width: 100%;
          height: 319px;
        }
      }
    }

    // section4 热销设备分类
    .sectionFourScroll {
      position: relative;
      .sectionFourScroll-item {
        position: relative;
        transition: all 0.2s linear;

        & > img {
          width: 312px;
          height: 416px;
        }
        &:hover {
          z-index: 2;
          box-shadow: 0 15px 30px rgb(0 0 0 / 25%);
          transform: translate3d(0, 2px, 0);
        }
      }
    }

    // section5 多样化服务 . 超值活动
    .sectionFive {
      display: grid;
      grid-template-columns: repeat(3, 424px);
      grid-template-rows: repeat(6, 200px);
      grid-row-gap: 24px;
      grid-column-gap: 24px;

      .sectionFive-item {
        display: inline-grid;
        cursor: pointer;
        transition: all 0.2s linear;
        position: relative;
        overflow: hidden;

        & > img {
          width: 424px;
          height: 100%;
        }
        &:hover {
          z-index: 2;
          box-shadow: 0 15px 30px rgb(0 0 0 / 25%);
          transform: translate3d(0, 2px, 0);
        }

        .sectionFive-item-info {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          padding: 48px 24px 24px;
          color: #fff;

          .sf-i-title {
            & > div:first-child {
              font-size: 24px;
              font-weight: bold;
            }
            & > div:last-child {
              font-size: 30px;
              font-weight: bold;
            }
          }
          .sf-i-more {
            position: absolute;
            bottom: 24px;
            right: 24px;
            font-size: 24px;

            & > span {
              vertical-align: middle;
            }

            & > img {
              width: 64px;
              height: 64px;
              margin-left: 16px;
              // vertical-align: middle;
            }
          }
        }
      }
      .sectionFive-item:nth-child(1) {
        grid-column: 1 / 1;
        grid-row: 1 / 4;

        .sf-i-title {
          text-align: right;
        }
      }
      .sectionFive-item:nth-child(2) {
        grid-column: 2 / 2;
        grid-row: 1 / 3;

        .sf-i-title {
          text-align: right;
        }
      }
      .sectionFive-item:nth-child(3) {
        grid-column: 3 / 3;
        grid-row: 1 / 3;
      }
      .sectionFive-item:nth-child(4) {
        grid-column: 1 / 1;
        grid-row: 4 / 7;
      }
      .sectionFive-item:nth-child(5) {
        grid-column: 2 / 2;
        grid-row: 3 / 5;
      }
      .sectionFive-item:nth-child(6) {
        grid-column: 2 / 2;
        grid-row: 5 / 7;
      }
      .sectionFive-item:nth-child(7) {
        grid-column: 3 / 3;
        grid-row: 3 / 7;
      }
    }
    // section6
    .sectionSix {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sectionSix-item {
        width: 424px;
        height: 566px;

        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .swiper-container {
      padding-bottom: 80px;
    }
    .swiper-wrapper {
      .swiper-pagination {
        text-align: right !important;
        position: absolute;
        transition: 0.3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
      }
      .swiper-pagination-bullets {
        bottom: 10px;
        left: 0;
        width: 100%;
      }
    }
    // 轮播前进后退按钮
    .scroll-btn-Left {
      position: absolute;
      top: -70px;
      bottom: 0;
      left: -80px;
      width: 80px;
      height: 80px;
      transform: rotate(180deg);
      cursor: pointer;
      margin: auto;
    }
    .scroll-btn-right {
      position: absolute;
      top: -70px;
      bottom: 0;
      right: -80px;
      width: 80px;
      height: 80px;
      cursor: pointer;
      margin: auto;
    }
  }

  .find-more {
    width: 160px;
    height: 48px;
    border: 1px solid #a2a2a2;
    border-radius: 36px;
    color: @color;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    margin: 0 auto 80px;
    transition: all 0.3s linear;

    &:hover {
      border: 1px solid #666;
    }
  }
  .isFindMoreStyle {
    border: 1px solid #fff !important;
    transform: translate3d(0, -1px, 0);
  }
}
</style>
